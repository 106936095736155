.uploadButton {
    width: 100%;
    height: 100%;
    fill: none;
    opacity: 0;
    background-color: #d1d1d1;
  }
  .uploadInputDiv > input {
    width: 100%;
  }
  .import-text {
    color: white;
    text-align: center;
    font-size: 14px;
  }
  .uploadCsvOuter {
    position: relative;
    background-color: #e63946 !important;
    color: white !important;
    display: flex;
    align-items: center;
    font-size: 10px !important;
    text-transform: capitalize !important;
    margin-left: 10px !important;
    justify-content: center;
    width: 16%;
  }
  .uploadInputDiv {
    top: 0px;
    left: 0px;
    width: 100%;
    position: absolute;
  }
  