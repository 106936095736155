@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local("Poppins"), url("../assets/fonts/Sofia/SofiaProLightAz.otf"),
    format("otf");
}

body {
  font-family: "Poppins", sans-serif !important;
  min-height: 100vh;
}

.home_page {
  background-color: #181f39;
}

.profile-card-dashboard {
  background-color: #1e2746 !important;
  max-width: 300px;
}

.profile-card-dashboard-product {
  background-color: #1e2746 !important;
  // max-width: 300px;
  max-height: 500px;

  overflow: auto !important;
}

.user-detail-list {
  padding-left: 0px !important;
}

.text-light-list {
  color: #777e9e !important;
  margin-bottom: 8px;
}

.profile-action-btn {
  background-color: #e63946 !important;
  color: white !important;
  width: 100% !important;
  border-radius: 14px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
}

.product-flex {
  display: inline-flex;
}

// dashboard card widget

.card-counter {
  // box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 20px 10px;
  background-color: #fff;
  height: 110px;
  border-radius: 3px;
  // width: 178px;
  transition: box-shadow 0.3s linear all;
}

.card-counter:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card-outer-div {
  padding: 0px !important;
  cursor: pointer;
  // right: 20px;
}

.card-counter.a0 {
  background-color: #fbd301;
  color: rgb(0, 0, 0);
  // width: 193px;
}
.card-counter.a0 p {
  color: #535151 !important;
}
.card-counter.a1 {
  background-color: #f14236;
  color: #fff;
  // width: 193px;
}

.card-counter.a2 {
  background-color: #ec979a;
  color: #fff;
  // width: 193px;
}

.card-counter.a3 {
  background-color: #4eac4f;
  color: #fff;
  // width: 193px;
}
.card-counter.a4 {
  background-color: #755246;
  color: #fff;
  // width: 193px;
}
// .card-counter.a5 {
//   background-color: #755246;
//   color: #fff;
//   // width: 193px;
// }

.card-counter i {
  font-size: 5em;
  opacity: 0.2;
}

.card-counter .count-numbers {
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 32px;
  display: block;
}

.card-counter .count-name {
  position: absolute;
  right: 35px;
  top: 65px;
  font-style: italic;
  text-transform: capitalize;
  opacity: 0.5;
  display: block;
  font-size: 18px;
}

.profile-outer-card {
  padding-right: 0px !important;
  padding-left: 0px !important;
  right: 38px;
  max-width: 300px !important;
}

.widget-cards-div {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.main-title {
  font-weight: 500 !important;
  font-size: 19px;
}

.card-subtitle {
  font-size: 12px !important;
}

// events table

.main-table-outer-div {
  top: 20px;
  // padding-left: 4px !important;
  // right: 20px;
}
.main-after-table-card {
  top: 20px;
  // padding-left: 0px !important;
}

.text-after-image {
  padding-left: 21px !important;
}

.product-main-title {
  font-size: 18px;
}

.venue-text {
  font-size: 12px;
}

.event-detail-text {
  font-size: 15px;
}

.event-detail-text p span {
  font-size: 15px;
  color: rgb(117, 115, 115) !important;
}

.product-image-dashboard {
  height: 200px;
  width: 100%;
  object-fit: contain;
  // border-radius: 50%;
}

.product-type-dash {
  color: white !important;
  font-size: 13px !important;
  height: 24px !important;
  font-weight: 600;
}

.dash-profile-img {
  height: 200px;
  width: 170px;
}

.img-span {
  width: 15%;
  padding-left: 30px;
}
.text-span {
  width: 35%;
}

.suggestion-item {
  color: black;
  padding: 7px !important;
}

.suggestion-item--active {
  color: black !important;
  padding: 7px !important;
}

.profile-update-btn {
  background-color: #108663 !important;
  color: white !important;
}

.dashboard-p-card {
  background-color: #18244e !important;
  margin-bottom: 10px !important;
  max-height: 365px !important;
  overflow: auto !important;
}

.dashboard-pname {
  color: white !important;
  font-size: 18px !important;
}
.dashboard-desc {
  color: white !important;
}

.p-component {
  font-family: "Poppins", sans-serif;
}

.MuiTypography-root {
  font-family: "Poppins", sans-serif !important;
}

.MuiButton-root {
  font-family: "Poppins", sans-serif !important;
}

#pdesc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.remove-card {
  display: none;
}
.dash-edit-action {
  background-color: #108663 !important;
  color: white !important;
  height: 26px;
}
.dash-detail-action {
  background-color: #aa27aa !important;
  color: white !important;
  height: 26px;
}
.dash-delete-action {
  background-color: #aa2727 !important;
  color: white !important;
  height: 26px;
}
.col-2dot4,
.col-sm-2dot4,
.col-md-2dot4,
.col-lg-2dot4,
.col-xl-2dot4 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-2dot4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
@media (min-width: 540px) {
  .col-sm-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 720px) {
  .col-md-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 960px) {
  .col-lg-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 1140px) {
  .col-xl-2dot4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
