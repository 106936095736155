.datatable-crud-demo .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.datatable-crud-demo .product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-crud-demo .p-dialog .product-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}

.datatable-crud-demo .confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

html {
  font-size: 14px;
}

body {
  background-color: #ffffff;
  font-weight: normal;
  color: #495057;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0.5em;
  margin-bottom: 50px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}

h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}
p {
  line-height: 1.5;
  margin: 0 0 1rem 0;
}

.card {
  margin-bottom: 2rem;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

.customer-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.customer-badge.status-qualified {
  background-color: #c8e6c9;
  color: #256029;
}

.customer-badge.status-unqualified {
  background-color: #ffcdd2;
  color: #c63737;
}

.customer-badge.status-negotiation {
  background-color: #feedaf;
  color: #8a5340;
}

.customer-badge.status-new {
  background-color: #b3e5fc;
  color: #23547b;
}

.customer-badge.status-renewal {
  background-color: #eccfff;
  color: #694382;
}

.customer-badge.status-proposal {
  background-color: #ffd8b2;
  color: #805b36;
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.product-badge.status-instock {
  background: #c8e6c9;
  color: #256029;
}

.product-badge.status-outofstock {
  background: #ffcdd2;
  color: #c63737;
}

.product-badge.status-lowstock {
  background: #feedaf;
  color: #8a5340;
}

.order-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.order-badge.order-delivered {
  background: #c8e6c9;
  color: #256029;
}

.order-badge.order-cancelled {
  background: #ffcdd2;
  color: #c63737;
}

.order-badge.order-pending {
  background: #feedaf;
  color: #8a5340;
}

.order-badge.order-returned {
  background: #eccfff;
  color: #694382;
}

.image-text {
  vertical-align: middle;
  margin-left: 0.5rem;
}

.p-multiselect-representative-option {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-representative-option img {
  vertical-align: middle;
  width: 24px;
}

.p-multiselect-representative-option span {
  margin-top: 0.125rem;
}

.p-column-filter {
  width: 100%;
}

.country-item {
  display: flex;
  align-items: center;
}

.country-item img.flag {
  width: 18px;
  margin-right: 0.5rem;
}

.flag {
  vertical-align: middle;
}

span.flag {
  width: 44px;
  height: 30px;
  display: inline-block;
}

img.flag {
  width: 30px;
}

.p-datatable .p-datatable-tbody > tr {
  background: #1e2746 !important;
  color: #736e6e !important;
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: #1e2746 !important;
  color: #afaaaa !important;
  border-bottom: 1px solid #444242 !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  border-bottom: 1px solid #444242 !important;
}

.p-paginator.p-component.p-paginator-bottom {
  background: #1e2746 !important;
  color: #736e6e !important;
}

.p-paginator.p-component.p-paginator-bottom .p-dropdown {
  background-color: #1e2746 !important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #1e2746 !important;
}
.table-card .p-toolbar {
  background: #1e2746 !important;
  border: none !important;
  margin-bottom: 0px !important;
}

.table-header {
  color: white !important;
  padding-right: 12px;
}

.edit-icon-table {
  background-color: #3570df !important;
  border-color: #3570df !important;
}

.delete-icon-table {
  background-color: #df3535 !important;
  border-color: #df3535 !important;
  color: white !important;
}
