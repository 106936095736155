.p-dialog .p-dialog-header {
  background-color: #1e2746 !important;
  color: white !important;
}

.p-dialog .p-dialog-content {
  background-color: #1e2746 !important;
  color: white !important;
}

.p-dialog .p-dialog-footer {
  background-color: #1e2746 !important;
  color: white !important;
}
