.posabs{
    position: absolute;
}
.dflex{
    display: flex;
}
.font18{
    font-size: 18px;
    
}
.font22{
    font-size: 22px;
    
}
.bordrad6{
    border-radius: 6px;
}
.boldbold{
    font-weight: bold;
}
.w100{
    width: 100%;
}
.w40per{
    width: 40%;
}
.w60per{
    width: 60%;
}
.w200px{
    width: 200px;
}
.h100{
    height: 100%;
}
.textcenter{
    text-align: center;
}
.textwhite{
    color: white;
}
.justbetween{justify-content: space-between;}
.flexcol{
    flex-direction: column;
}

.bgwhite{
    background-color: white;
}
.p5{
    padding: 5px;
}
.p10{
    padding: 10px;
}
.outermodal{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px;
    /* background-color: black; */
    background-color: rgba(0, 0, 0, 0.4); 

}
.settomiddle{
    top: 155px;
    width: 30%;
    left: 35%;
    height: 200px;
    background-color: rgba(30, 39, 70);
}
.closebutton{
    background-color: #e63946;
}