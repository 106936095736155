.forgetpasswordform {
  width: 60%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
  padding-top: 50px;
}
.forgetformcontrol {
  box-sizing: border-box;
  border: none;
  padding: 30px auto;
}

.Loginform {
  width: 60%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
  padding-top: 50px;
}
.loginformcontrol {
  box-sizing: border-box;
  border: none;
}

.resetpasswordform {
  width: 60%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
  padding-top: 50px;
}

.signUp-form {
  width: 60%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
  padding-top: 50px;
}
.titlesection {
  background-color: #1d3557;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-size: 800px;
  // background-position: left center;
}
.logoimage {
  position: relative;
  left: 210px;
  top: 250px;
}

.formcontrol1 {
  box-sizing: border-box;
  border: none;
}

:focus {
  outline: none;
}
.MuiInputBase-input {
  color: #000 !important;
}

input[type="text"] {
  font: 15px/24px "Lato", Arial, sans-serif;
  color: #000;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}
input[type="email"] {
  font: 15px/24px "Lato", Arial, sans-serif;
  color: #000;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}
input[type="password"] {
  font: 15px/24px "Lato", Arial, sans-serif;
  color: #000;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}

.effect-16 {
  border: 0;
  padding: 4px 0;
  border-bottom: 1px solid #ccc;
  margin: 15px auto;
  background-color: transparent;
  z-index: 10;
}

.effect-16 ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #a8dadc;
  transition: 0.4s;
  margin: 15px auto;
}

.effect-16:focus ~ .focus-border,
.has-content.effect-16 ~ .focus-border {
  width: 100%;
  transition: 0.4s;
}
.effect-16 ~ label {
  position: absolute;
  left: 0;
  width: 100%;
  color: #1d3557;
  transition: 0.3s;
  z-index: 1;
  letter-spacing: 0.5px;
  font-size: 15px;
  font-weight: 500;
}
.effect-16:focus ~ label,
.has-content.effect-16 ~ label {
  top: -5px;
  font-size: 12px;
  color: #1d3557;
  transition: 0.3s;
  z-index: 1;
}

.submitbtn {
  width: 100%;
  margin: auto;
  border: none;
  color: #ffffff;
  background-color: #e63946;
  z-index: 1;
}

//

body {
  background-color: #d0d0ce;
  min-height: 100vh;
}

// .brand-wrapper {
//   margin-bottom: 19px;
// }
.brand-wrapper .logo {
  height: 37px;
}

.login-card {
  border: 0;
  // border-radius: 27.5px;
  box-shadow: 0 10px 30px 0 rgba(172, 168, 168, 0.43);
  overflow: hidden;
}
.login-card-img {
  border-radius: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.login-card .card-body {
  padding: 55px 60px 60px;
  padding-bottom: 0px !important;
}
.login-card .card-body.signup-body {
  padding: 10px 60px 60px;
  padding-bottom: 0px !important;
}
.login-card .card-body.forgot-pass {
  padding: 144px;
  padding-bottom: 85px !important;
}
@media (max-width: 422px) {
  .login-card .card-body {
    padding: 35px 24px;
  }
  .login-card .card-body.forgot-pass {
    padding: 35px 24px;
  }
  .login-card .card-body.signup-body {
    padding: 35px 24px;
  }
}
.login-card-description {
  font-size: 25px;
  color: #000;
  font-weight: normal;
  margin-bottom: 0px !important;
}

.signup-desc {
  color: #13233a;
  font-size: 19px;
}
.login-card form {
  max-width: 435px;
}
.login-card .form-control {
  border: 1px solid #d5dae2;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 15px 15px;
  margin-bottom: 10px;
  min-height: 25px;
  font-size: 13px;
  line-height: 15px;
  font-weight: normal;
}
.login-card .form-control::-webkit-input-placeholder {
  color: #919aa3;
}
.login-card .form-control::-moz-placeholder {
  color: #919aa3;
}
.login-card .form-control:-ms-input-placeholder {
  color: #919aa3;
}
.login-card .form-control::-ms-input-placeholder {
  color: #919aa3;
}
.login-card .form-control::placeholder {
  color: #919aa3;
}
.login-card .login-btn {
  padding: 13px 20px 12px;
  background-color: #e63946;
  border-radius: 20px;
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;
  margin-bottom: 24px;
}
.login-card .login-btn:hover {
  border: 1px solid #000;
  background-color: transparent;
  color: #000;
}
.login-card .forgot-password-link {
  font-size: 14px;
  color: #919aa3;
  margin-bottom: 12px;
}

.form-control:focus {
  box-shadow: none !important;
}
.login-card-footer-text {
  font-size: 16px;
  color: #0d2366;
  margin-bottom: 60px;
}
@media (max-width: 767px) {
  .login-card-footer-text {
    margin-bottom: 24px;
  }
}
.login-card-footer-nav a {
  font-size: 14px;
  color: #919aa3;
}

.form-control-label {
  color: #13233a;
}

.forgot-password-div {
  text-align: end;
}
.or-div {
  text-align: center;
}
.forgot-password-div a {
  color: #13233a !important;
}

.extra-div {
  text-align: center;
}

.extra-div p {
  color: #e63946;
}
.extra-div span {
  color: #888787;
}

.for-pass {
  color: #13233a !important;
  font-size: 20px;
}

.click-here {
  cursor: pointer;
}
.click-here:hover {
  color: #0d2366;
}
a {
  text-decoration: none !important;
}

/*# sourceMappingURL=login.css.map */

.p-inputtext:enabled:focus {
  box-shadow: none !important;
}

.update-password-btn {
  background-color: #e63946 !important;
  color: white !important;
  border-radius: 20px !important;
  width: 40%;
  text-transform: capitalize !important;
  font-size: 14px !important;
}

.p-password-panel {
  display: none !important;
}
.email-verify-text {
  text-decoration: underline;
}
